<template>
  <base-layout>
    <!--header-bar></header-bar-->
    <ion-searchbar
        show-cancel-button="focus"
        @ionChange="filter(search)"
        :value="search"
        @ionInput="search = $event.target.value;"
    ></ion-searchbar>
    <IonSegment :value="activeDay" scrollable style="justify-content: flex-start">

      <!--IonSegmentButton style="min-width:50px; width: 50px;max-width:50px" @click="changeToMap()">
        <ion-icon :icon="locationOutline"></ion-icon>
      </IonSegmentButton-->
      <IonSegmentButton v-for="(value, key) in days" :key="key" :value="key" style="min-width:250px" mode="md"
                        @click="activeDay = key">{{ value.label }}
      </IonSegmentButton>
    </IonSegment>

    <ion-content>
      <div>
        <ion-list class="wcpt-session-list" v-if="activeDay && days[activeDay]">
          <div v-for="session in days[activeDay].sessions" :key="session.id">
            <!-- add color --- :style="{'background': 'linear-gradient(65deg,' +  session.color +', transparent'}"-->
            <a @click="showDisclaimer(session)" class="wcpt-session-item " v-if="!session.isHeader"
               :style="{'border-color':  session.color + ' !important'}">

              <img class="picture" src="@/assets/icon/avatar.png"
                   v-if="!session.chair || !session.chair[0] || !session.chair[0].vitae_picture">
              <img class="picture"
                   :src="getChairPicture(session)"
                   v-if="session.chair && session.chair[0] && session.chair[0].vitae_picture">
              <div class="head">
                <div class="type">{{ session.session_type_text }}</div>
                <div class="time">{{ moment(session.start_time).format('dddd HH:mm') }} -
                  {{ moment(session.end_time).format('HH:mm') }} (CEST)
                </div>
              </div>
              <div class="content">
                <div class="title">{{ session.title }}</div>
                <div class="chair" v-if="session.chair.length > 0"><strong>Chair:</strong> <span
                    v-for="chair in session.chair" :key="chair">{{ chair.display_name }}</span></div>
                <div class="speaker" v-if="session.speakers.length > 0">
                  <strong>Speakers:</strong> <span v-for="speaker in session.speakers"
                                                                             :key="speaker">{{
                    speaker.first_name
                  }} {{ speaker.last_name }} </span>
                </div>
              </div>
              <!--add color --- :style="{'background-color': session.color}"-->
              <div class="shape" :style="{'background-color': session.color}">
                <!--add color--- :style="{'color': session.font_color}"-->
                <div class="room" v-if="session.location" :style="{'color': session.font_color}"><p class="channel"
                                                                                                    >
                  {{ session.location.name }}</p></div>
              </div>
            </a>

            <div v-if="session.isHeader && !isOnDemand" class="item item-divider" style="width:100vw; padding-left:20px">
              <h2> {{ moment(session.start_time).format('HH:mm') }}</h2>
            </div>
          </div>
        </ion-list>
      </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent, IonList, IonSearchbar, IonSegment, IonSegmentButton, alertController, isPlatform} from '@ionic/vue';
import {defineComponent} from 'vue';
import {menuController} from "@ionic/vue";
import {mapActions, mapState} from 'vuex';
import moment from 'moment';
import {gridOutline,locationOutline} from 'ionicons/icons';

export default defineComponent({
  name: 'Home',
  data() {
    return {
      sessions: [],
      days: {},
      activeDay: '',
      search: "",
      filtered: [],
      location:null,
    }
  },

  setup() {
    return {
      gridOutline,
      locationOutline
    }
  },
  components: {
    IonContent,
    IonList,
    IonSegment,
    IonSegmentButton,
    IonSearchbar
  },
  computed: {
    ...mapState('sync', ['updateFinished']),
    filteredSessions: function () {
      return this.filtered.length ? this.filtered : this.sessions;
    }
  },
  methods: {
    ...mapActions('sessions', ['getAllSessions', 'getSessionsForLocation']),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    openMenu() {
      menuController.open("app-menu")
    },

    filter(term) {
      if (term.length > 0) {
        this.filtered = this.sessions.filter(session =>
            Object.keys(session).some(k =>
                session[k]
                    ? session[k].toString().toLowerCase().includes(term.toLowerCase())
                    : ""
            )
        );
        this.filtered = this.groupList(this.filtered, 'start_time');
        this.days = {};
        this.prepareDays(this.filtered);
      } else {
        this.filtered = [];
        this.resetList();
      }
    },
    resetList() {
      this.days = {};
      this.prepareDays(this.sessions);
    },
    async fetchSessions() {

      this.sessions = (await this.getSessionsForLocation(this.location)).sort((a, b) => {
        return new Date(a.start_time) - new Date(b.start_time);
      });

      this.sessions = this.groupList(this.sessions, 'start_time');
      this.days = {};
      this.prepareDays(this.sessions);
    },
    groupList(list, selector) {
      let letters = [];
      let currentCharCode = '2010-01-01T00:00:00'; //$filter('date')(date, format, timezone)
      let sortedList = [];

      function addLetter(code) {
        let letter = code
        sortedList.push({
          isHeader: true,
          head: letter,
          start_time: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector];
          let difference = new Date(itemCharCode) - new Date(currentCharCode);
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });
      return sortedList;
    },
    prepareDays(sessions) {
      for (let i = 0; i < sessions.length; i++) {
        let session = sessions[i];
        if (session.start_time) {
          let start = session.start_time.split('T')[0];
          if (!this.activeDay) {
            this.activeDay = start;
          }
          if (!this.days[start]) {
            this.days[start] = {};
            this.days[start].label = moment(start).format('dddd, MMMM D')
            this.days[start].sessions = [];
          }
          this.days[start].sessions.push(session);
        }
      }
    },

    getChairPicture(session) {
      let link = ''
      if (session.chair && session.chair[0] && session.chair[0].vitae_picture) {
        link = 'https://fileserver-188ab.kxcdn.com/file/' + session.chair[0].vitae_picture.split('/')[4];
      }
      return link;
    },

    changeToMap() {
      this.$router.push('/app/locationmap/' + this.$route.params.id);
    },

    async showDisclaimer(session) {
      if (session.disclaimer && session.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: session.title,
              message: '<strong>' + session.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(session.title);
                    this.$router.push('/app/sessions/' + session.id);
                  },
                },
              ],
            });
        return alert.present();
      } else {
        this.$router.push('/app/sessions/' + session.id);
      }

    }


  },
  async created() {
    this.location = this.$route.params.id;
    this.fetchSessions();
    this.moment = moment;
  },
  watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(to) {
        if (to.name == 'SessionByLocation') {
          this.isIos = isPlatform('ios')
          this.location = to.params.id;
          this.fetchSessions();
        }
      }
    },
    updateFinished: async function (newVal) {
      if (newVal) {
        this.fetchSessions()
      }
    },
  },
});
</script>
<style lang="scss">

</style>